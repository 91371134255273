<template>
  <div class="main_containter" :class="system.isPhone ? 'wap' : 'pc'">
    <div class="pd_box">
      <el-row :gutter="gutter">
        <!--类目选择  -->
        <el-col
          v-bind="{
            xs: 24,
            sm: 24,
            md: 24,
            lg: 18,
            xl: 18,
          }"
        >
          <el-form-item
            prop="categoryJson"
            :label="$t(`popFillInInfomation.brand.categoryInfo.category`)"
          >
            <el-cascader
              ref="category"
              v-model="form.categoryJson"
              :options="categoryList"
              :props="cascaderProps"
              style="width:100%"
              placeholder="请选择经营类目"
              @change="handleChange"
              @focus="categoryListFocus"
              v-bind="otherBind"
              clearable
            />
          </el-form-item>
        </el-col>
        <el-col
          v-bind="{
            xs: 24,
            sm: 24,
            md: 24,
            lg: 6,
            xl: 6,
          }"
        >
          <a
            :href="
              `https://assets-js.myazstore.com/xlsxs/基础保证金标准.docx?timestamp=${new Date().getTime()}`
            "
            class="a_font"
            >类目及保证金查询</a
          >
        </el-col>
      </el-row>
    </div>
    <!-- <div class="list_box">
      <ul>
        <li v-for="item in form.categoryArray" :key="item">{{ item }}</li>
      </ul>
    </div> -->
  </div>
</template>

<script>
//公共组件导入处;
// import azgCard from "@/views/publicComponents/azgCard";
//子组件导入处;

//方法导入处;

export default {
  // components: {
  //   azgCard
  // },
  data() {
    return {
      cascaderProps: {
        multiple: true,
        // emitPath: false,
        checkStrictly: true,
        label: "name",
        value: "path",
        // children: "childrenCategories",
      },
      otherBind: {
        size: "medium",
      },
      props: { multiple: true },
      categoryList: [],
    };
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    gutter: [Number],
  },
  computed: {
    form: {
      get: function() {
        return this.formData;
      },
      set: function(value) {
        this.$emit("update:formData", value);
      },
    },
  },
  methods: {
    getCategoryData() {
      this.$request(
        "/api/front/product/backgroundCategory/findBackcategoreyPop",
        {
          method: "GET",
        }
      )
        .then((res) => {
          this.categoryList = res.data;
        })
        .catch(() => {});
    },
    handleChange() {
      this.$nextTick(() => {
        let checkedNodes = this.$refs.category.getCheckedNodes();
        let path = "";
        checkedNodes = checkedNodes.filter((item) => {
          if (path != "") {
            if (item.path.includes(path)) {
              return false;
            } else {
              path = item.value;
              return true;
            }
          } else if (item.hasChildren == false) {
            path = "";
            return true;
          } else {
            path = item.value;
            return true;
          }
        });
        // console.log(checkedNodes);

        this.form.categoryArray = checkedNodes.map((item) =>
          item.pathLabels.join(">")
        );
        let categoryJson1 = [...checkedNodes.map((item) => item.path)];
        this.form.categoryJson1 = JSON.parse(JSON.stringify(categoryJson1));

        // let categoryArray2 = this.$refs.category
        //   .getCheckedNodes({
        //     leafOnly: true
        //   })
        //   .map(item => item.pathLabels.join(">"));

        // console.log(JSON.parse(JSON.stringify(this.form.categoryArray)));
        // console.log("------------------------------------");
        // console.log(categoryArray2);

        // let gongyou = this.form.categoryArray.filter(item => {
        //   return !categoryArray2.includes(item);
        // });
        // console.log("gongyou", gongyou);
        // console.log(this.$refs.category.getCheckedNodes());
        // console.log(value, arguments);
      });
    },

    // duoji(arr, jiegou = {}) {
    //   let jiegouItem = null;
    //   arr.forEach(item => {
    //     if (jiegouItem) {
    //       if (!jiegouItem[item]) {
    //         jiegouItem[item] = {};
    //         jiegouItem = jiegouItem[item];
    //       } else {
    //         jiegouItem = jiegouItem[item];
    //       }
    //     } else if (jiegou[item]) {
    //       jiegouItem = jiegou[item];
    //     } else {
    //       jiegou[item] = {};
    //       jiegouItem = jiegou[item];
    //     }
    //   });
    //   return jiegou;
    // },

    categoryListFocus() {
      console.log(arguments);
      setTimeout(() => {
        document
          .getElementsByClassName("el-cascader__dropdown")
          .forEach((element) => {
            console.log(element.style.left);
            element.style.left = "0px";
          });
      }, 0);
    },
  },
  mounted() {
    this.getCategoryData();
  },
  beforeDestroy() {},
  activated() {},
};
</script>
<style scoped lang="less">
// ::v-deep .el-form-item__content {
//   margin-left: 0 !important;
// }
.list_box {
  height: 80px;
  overflow-y: scroll;
  border: 1px solid #dcdfe6;
  border-radius: 10px;
  color: black;
  margin: 0 10px 15px 250px;
  padding: 40px;
  li {
    line-height: 30px;
  }
}
.pc {
  ::v-deep .box_title {
    font-size: calc(100vw * 1.4 / 192);
    font-weight: 600;
  }
  ::v-deep .el-cascader--medium,
  .el-date-editor {
    width: 100%;
    // max-width: 400px;
  }
  ::v-deep .el-cascader .el-input__suffix {
    z-index: 100;
  }
  ::v-deep .el-cascader .el-cascader__tags {
    right: 1px;
    top: 1px;
    transform: unset;
    width: 80%;
  }
}
.wap {
  ::v-deep .box_title {
    font-size: calc(100vw * 1.5 / 37.5);
    font-weight: 600;
  }
}
.a_font {
  font-size: 14px;
  line-height: 40px;
}
</style>
