<template>
  <div class="main_containter" :class="system.isPhone ? 'wap' : 'pc'">
    <div style="padding-left: 26px;">
      <el-row :gutter="gutter">
        <el-col v-bind="row1">
          <!-- 多文件上传下载 -->
          <el-row :gutter="20">
            <el-col :span="6">
              <upload-file-more
                prop="authorizationUrl"
                :tips="''"
                :rules="rules.authorizationUrl"
                :dataList.sync="form.authorizationUrl"
                :label="
                  $t(`popFillInInfomation.authorizationInfo.authorizationUrl`)
                "
              >
              </upload-file-more>
            </el-col>
            <el-col :span="18">
              <a
                :href="
                  `https://assets-js.myazstore.com/xlsxs/授权代表声明书.docx?timestamp=${new Date().getTime()}`
                "
                class="a_font"
                >下载《签约授权书》模板</a
              >
            </el-col>
          </el-row>

          <!-- <uplode-input3
            :asyncUpdate="true"
            tip="签约授权书"
            prop="authorizationUrl"
            :rules="rules.authorizationUrl"
            :label="
              $t(`popFillInInfomation.authorizationInfo.authorizationUrl`)
            "
            keyName="authorizationUrl"
            :value="form.authorizationUrl"
            @uplodeChange="getIdcardUrlInfo"
          ></uplode-input3> -->
        </el-col>
        <!-- 签约授权书有效期 -->
        <el-col v-bind="row2">
          <el-form-item
            ref="authorizationPeriod"
            :prop="
              form.authorizationLongPeriod == 1
                ? 'authorizationLongPeriod'
                : 'authorizationPeriod'
            "
            :rules="rules.authorizationPeriod1"
            :label="
              $t(`popFillInInfomation.authorizationInfo.authorizationPeriod`)
            "
          >
            <div class="yihang">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="form.authorizationPeriod"
                type="date"
                v-bind="otherBind"
                placeholder="请选择"
                :editable="!system.isPhone"
                :disabled="form.authorizationLongPeriod == 1"
              >
              </el-date-picker>
              <el-checkbox
                @change="changqiEvent"
                :true-label="1"
                :false-label="0"
                v-model="form.authorizationLongPeriod"
                t="长期"
              >
                {{ $t(`popFillInInfomation.longTime`) }}
              </el-checkbox>
            </div>
          </el-form-item>
        </el-col>
        <el-col v-bind="row2"><div class="width100"></div></el-col>
        <!--  -->
        <el-col v-bind="row2">
          <el-form-item
            :rules="rules.authorizerLocationType"
            prop="authorizerLocationType"
            :label="
              $t(`popFillInInfomation.authorizationInfo.authorizerLocationType`)
            "
          >
            <el-select
              v-model="form.authorizerLocationType"
              placeholder="请选择"
            >
              <el-option
                v-for="item in authorizerLocationTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-bind="row2"><div class="width100"></div></el-col>
        <el-col v-if="form.authorizerLocationType == 5" v-bind="row2">
          <uplode-input3
            :asyncUpdate="true"
            tip="证件照片"
            prop="idcardUrl"
            :rules="rules.idcardFrontUrl"
            :label="$t(`popFillInInfomation.authorizationInfo.idcardUrl`)"
            keyName="idcardUrl"
            :value="form.idcardUrl"
            @uplodeChange="getIdcardUrlInfo"
          ></uplode-input3>
        </el-col>
        <el-col v-if="form.authorizerLocationType != 5" v-bind="row2">
          <uplode-input3
            :asyncUpdate="true"
            tip="法人身份证人像面"
            prop="idcardFrontUrl"
            :rules="rules.idcardFrontUrl"
            :label="$t(`popFillInInfomation.authorizationInfo.idcardFrontUrl`)"
            keyName="idcardFrontUrl"
            :value="form.idcardFrontUrl"
            @uplodeChange="getIdcardUrlInfo"
          ></uplode-input3>
        </el-col>
        <el-col v-if="form.authorizerLocationType != 5" v-bind="row2">
          <uplode-input3
            :asyncUpdate="true"
            tip="法人身份证国徽面"
            prop="idcardBackUrl"
            :rules="rules.idcardBackUrl"
            :label="$t(`popFillInInfomation.authorizationInfo.idcardBackUrl`)"
            keyName="idcardBackUrl"
            :value="form.idcardBackUrl"
            @uplodeChange="getIdcardUrlInfo"
          ></uplode-input3>
        </el-col>
      </el-row>
    </div>
    <div class="content_box">
      <el-row :gutter="gutter">
        <!--被授权人姓名  -->
        <el-col v-bind="row2">
          <text-input
            :otherBind="otherBind"
            prop="idcardName"
            :rules="rules.idcardName"
            v-model="form.idcardName"
            :label="$t(`popFillInInfomation.authorizationInfo.idcardName`)"
          ></text-input>
        </el-col>

        <!--被授权人姓名证件号码：  -->
        <el-col v-bind="row2">
          <text-input
            :otherBind="otherBind"
            prop="idcardNumber"
            :rules="rules.idcardNumber"
            v-model="form.idcardNumber"
            :label="$t(`popFillInInfomation.authorizationInfo.idcardNumber`)"
          ></text-input>
        </el-col>

        <!--被授权人证件有效期：  -->
        <el-col v-bind="row2">
          <el-form-item
            ref="authorizerCredentialsPeriod"
            :prop="
              form.idcardLongPeriod == 1
                ? 'idcardLongPeriod'
                : 'authorizerCredentialsPeriod'
            "
            :label="
              $t(
                `popFillInInfomation.authorizationInfo.authorizerCredentialsPeriod`
              )
            "
          >
            <div class="yihang">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="form.authorizerCredentialsPeriod"
                type="date"
                v-bind="otherBind"
                placeholder="请选择"
                :editable="!system.isPhone"
                :disabled="form.idcardLongPeriod == 1"
              >
              </el-date-picker>
              <el-checkbox
                @change="changqiEvent1"
                :true-label="1"
                :false-label="0"
                v-model="form.idcardLongPeriod"
                t="长期"
              >
                {{ $t(`popFillInInfomation.longTime`) }}
              </el-checkbox>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import uplodeInput3 from "@/views/formComponents/uplodeInput3";
import textInput from "@/views/formComponents/textInput";
import uploadFileMore from "@/views/formComponents/uploadfilemore";
export default {
  components: { uplodeInput3, textInput, uploadFileMore },
  data() {
    return {
      otherBind: {
        size: "medium",
      },
      authorizerLocationTypeOptions: [
        {
          value: 5,
          label: "海外",
        },
        {
          value: 1,
          label: "中国大陆",
        },
        {
          value: 2,
          label: "中国香港",
        },
        {
          value: 3,
          label: "中国澳门",
        },
        {
          value: 4,
          label: "中国台湾",
        },
      ],
    };
  },
  props: {
    row1: [Object],
    row2: [Object],
    gutter: [Number],
    formData: {
      type: Object,
      default: () => {},
    },
    rules: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    form: {
      get: function() {
        return this.formData;
      },
      set: function(value) {
        this.$emit("update:formData", value);
      },
    },
  },
  methods: {
    uplodeChange(value) {
      this.form = { ...this.form, ...value };
      this.$parent.$parent.$parent.$refs["applyAgentForm"].validateField(
        Object.keys(value)[0]
      );
    },
    changqiEvent() {
      this.$refs["authorizationPeriod"].resetField();
    },
    changqiEvent1() {
      this.$refs["authorizerCredentialsPeriod"].resetField();
    },
    getIdcardUrlInfo(value) {
      this.uplodeChange(value);
    },
  },
};
</script>
<style scoped lang="less">
.content_box {
  border: 1px solid rgba(18, 18, 18, 0.05);
  border-radius: 2px;
  padding: 24px;
  margin-top: 12px;
}
::v-deep .el-cascader--medium,
.el-date-editor {
  width: 100%;
}
.yihang {
  display: flex;
  align-items: center;
  gap: 14px;
}
.wap {
  .content_box {
    margin-top: 24px;
  }
}

.width100 {
  width: 100%;
  height: 62px;
  line-height: 40px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}
::v-deep .el-select {
  width: 100%;
}
.a_font {
  font-size: 14px;
  line-height: 40px;
}
</style>
